import { Overmind } from './'
import { ResolveActions } from './internalTypes'
import { IS_OPERATOR } from './utils'

/** ===== PUBLIC API
 */
export { EventType } from './internalTypes'

export type IConfiguration = {
  onInitialize?: any
  state?: {}
  effects?: {}
  actions?: {}
}

export type IState = {
  [key: string]:
    | IState
    | string
    | number
    | boolean
    | object
    | null
    | undefined
}


export interface IConfig<ThisConfig extends IConfiguration> {
  state: ThisConfig['state'] & {}
  actions: ThisConfig['actions'] & {}
  effects: ThisConfig['effects'] & {}
}

export type IContext<ThisConfig extends IConfiguration> = {
  state: ThisConfig['state']
  actions: ResolveActions<ThisConfig['actions']>
  effects: ThisConfig['effects']
  revertable: (mutationsCallback: () => any) => () => void
}

export interface IReaction<ThisConfig extends IConfiguration> {
  <O>(
    stateCallback: (state: ThisConfig['state']) => O,
    updateCallback: (value: O) => void,
    options?: {
      nested?: boolean
      immediate?: boolean
    }
  ): () => void
}

export interface IAction<
  ThisConfig extends IConfiguration,
  Value,
  ReturnValue = void | Promise<void>
> {
  <InferredReturnValue extends ReturnValue>(
    context: IContext<ThisConfig>,
    value: Value
  ): ReturnValue extends Promise<any>
    ? ReturnValue
    : InferredReturnValue | ReturnValue
}

// We do not type operators as their low level implementation, but rather
// how it is consumed by the developer
export interface IOperator<
  ThisConfig extends IConfiguration,
  Input,
  Output = Input
> {
  (context: IContext<ThisConfig>, value: Input): Output
  [IS_OPERATOR]: true
}

export interface IOnInitialize<ThisConfig extends IConfiguration> {
  (context: IContext<ThisConfig>, value: Overmind<ThisConfig>): void
}
